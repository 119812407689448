import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ee960e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices-invoice-payments-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "box__filter" }
const _hoisted_6 = {
  key: 0,
  class: "box__list"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "el-table-column__action" }
const _hoisted_22 = { class: "el-table-column__action" }
const _hoisted_23 = {
  key: 0,
  class: "pagination"
}
const _hoisted_24 = {
  key: 1,
  class: "box__message"
}
const _hoisted_25 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorInvoicesInvoicePaymentsListFormFilterIndexVue = _resolveComponent("AppAdministratorInvoicesInvoicePaymentsListFormFilterIndexVue")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!
  const _component_AppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue = _resolveComponent("AppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue")!
  const _component_AppAdministratorInvoicesInvoicePaymentsListFormRemoveIndexVue = _resolveComponent("AppAdministratorInvoicesInvoicePaymentsListFormRemoveIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.invoicePayments), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AppAdministratorInvoicesInvoicePaymentsListFormFilterIndexVue, {
          partners: _ctx.partners,
          onGetInvoicePayments: _ctx.getInvoicePayments
        }, null, 8, ["partners", "onGetInvoicePayments"])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_el_table, {
              data: _ctx.invoicePayments,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.excerpt.substring(0,3)}.`,
                  width: "80"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsTransactionSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, " #" + _toDisplayString(_ctx.getInformation(scope.row.transaction.excerpt.content.ordinalNumber)) + "/" + _toDisplayString(_ctx.getDateYearFormat(scope.row.transaction.excerpt.content.date)), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, " N/A "))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.transaction.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsTransactionSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, " #" + _toDisplayString(scope.row.transaction.content.ordinalNumber), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_10, " N/A "))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.date}`,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getDateFormat(scope.row.content.date)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoiceNumberInYearIRAURA}`,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsIncomingInvoicePayment(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.getInvoiceNumberInYear(scope.row.incoming_invoice.content.invoiceNumberInYear, scope.row.incoming_invoice.content.year)), 1))
                      : _createCommentVNode("", true),
                    (_ctx.getIsOutgoingInvoicePayment(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.getInvoiceNumberInYear(scope.row.outgoing_invoice.content.invoiceNumberInYear, scope.row.outgoing_invoice.content.year)), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.invoiceNumber}`,
                  width: "180"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsIncomingInvoicePayment(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.getInformation(scope.row.incoming_invoice.content.invoiceNumber)), 1))
                      : _createCommentVNode("", true),
                    (_ctx.getIsOutgoingInvoicePayment(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.getInformation(scope.row.outgoing_invoice.content.invoiceNumber)), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.partner}`,
                  width: "200"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsIncomingInvoicePayment(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.getInformation(scope.row.incoming_invoice.partner.content.title)), 1))
                      : _createCommentVNode("", true),
                    (_ctx.getIsOutgoingInvoicePayment(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.getInformation(scope.row.outgoing_invoice.partner.content.title)), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.paymentType.substring(0,3)}.`,
                  width: "40"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.payment_type.content.title), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.paymentPurpose}`,
                  "min-width": "360"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsTransactionSet(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(scope.row.transaction.content.paymentPurpose), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_18, " N/A "))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.amountOfPayment}`,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(_ctx.getCurrencyFormatEUR(scope.row.content.amount.eur)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.compensation.substring(0,4)}.`,
                  width: "100"
                }, {
                  default: _withCtx((scope) => [
                    (_ctx.getIsInvoicePaymentCompensation(scope.row))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.translation.yes), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.translation.no), 1))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.review}`,
                  align: "center",
                  width: "132",
                  fixed: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_el_button, {
                        class: "el-button--primary el-button--extra-small el-button--block",
                        onClick: ($event: any) => (_ctx.setAppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.review), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  label: `${_ctx.translation.remove}`,
                  align: "center",
                  width: "132",
                  fixed: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_el_button, {
                        class: "el-button--primary-dark el-button--extra-small el-button--block",
                        onClick: ($event: any) => (_ctx.setAppAdministratorInvoicesInvoicePaymentsListFormRemoveIndexVue(scope.row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.remove), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data"]),
            (_ctx.isPaginationVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createVNode(_component_el_pagination, {
                    background: "",
                    layout: "prev, pager, next",
                    "page-size": 24,
                    total: _ctx.pagination.total,
                    "current-page": _ctx.pagination.current_page,
                    onCurrentChange: _ctx.setPagination
                  }, null, 8, ["total", "current-page", "onCurrentChange"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_AppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue, {
      ref: "AppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue",
      "invoice-payment": _ctx.invoicePayment
    }, null, 8, ["invoice-payment"]),
    _createVNode(_component_AppAdministratorInvoicesInvoicePaymentsListFormRemoveIndexVue, {
      ref: "AppAdministratorInvoicesInvoicePaymentsListFormRemoveIndexVue",
      "invoice-payment": _ctx.invoicePayment,
      "partner-id": _ctx.partner_id,
      year: _ctx.year,
      pagination: _ctx.pagination,
      onGetInvoicePayments: _ctx.getInvoicePayments
    }, null, 8, ["invoice-payment", "partner-id", "year", "pagination", "onGetInvoicePayments"])
  ]))
}