
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

// Components
import {
  ElDialog,
} from "element-plus";
import {
  Right,
} from "@element-plus/icons-vue";

// Services
import {getDateFormat, getDateYearFormat} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "invoicePayment",
  ],
  components: {
    ElDialog,
    Right,
  },
})
export default class AppAdministratorInvoicesInvoicePaymentsListFormLookupIndexVue extends Vue {
  isDialogVisible = false;

  invoicePayment: AppInvoicePaymentIndex = new AppInvoicePaymentIndex();

  get isTransactionSet(): boolean {
    return this.invoicePayment.transaction_id !== null;
  }

  get isIncomingInvoicePayment(): boolean {
    return this.invoicePayment.incoming_invoice_id !== null;
  }

  get isOutgoingInvoicePayment(): boolean {
    return this.invoicePayment.outgoing_invoice_id !== null;
  }

  get translation(): any {
    return getTranslation([
      "accountNumber",
      "amountOfPayment",
      "bank",
      "date",
      "excerpt",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceType",
      "paymentType",
      "partner",
      "paymentPurpose",
      "review",
      "reviewExcerpt",
      "transaction",
    ]);
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getDateYearFormat(data: string): string {
    return getDateYearFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }
}
